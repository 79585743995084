import axios from "axios"
import Alerter from "./alerter"
import { documentObj as document, windowObj as window } from "./serverSideRendering"

const CSRF_TOKEN = document.querySelector("[name=csrf-token]")?.content || ""
const DEFAULT_TIMEOUT = 10000
const HTTP_UNPROCESSABLE_ENTITY = 422

export const apiClient = axios.create({
  timeout: DEFAULT_TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    "X-CSRF-TOKEN": CSRF_TOKEN,
  },
  validateStatus: status => status >= 200 && status < 300,
})

apiClient.interceptors.response.use(
  response => {
    if (response.headers.location) {
      window.location = response.headers.location
    }
    return response
  },
  error => {
    if (error.response?.headers.location) {
      window.location.replace(error.response.headers.location)
    }
    return Promise.reject(error)
  }
)

export const request = requestConfig => apiClient.request(requestConfig)

const handleRequest = (method, url, payload, redirectUrl, onSuccess, onError, onFinally, options) => {
  const requestPromise = redirectUrl
    ? apiClient[method](url, payload, options).then(() => window.location.replace(redirectUrl))
    : apiClient[method](url, payload, options).then(response => {
        if (onSuccess) onSuccess(response)
      })

  return requestPromise.catch(onError || showErrorMessage).finally(() => {
    if (onFinally) onFinally()
  })
}

export const postAndRedirect = (url, redirectUrl, payload, onError) =>
  handleRequest("post", url, payload, redirectUrl, null, onError)

export const post = (url, payload, onSuccess, onError, onFinally, options) =>
  handleRequest("post", url, payload, null, onSuccess, onError, onFinally, options)

export const putAndRedirect = (url, redirectUrl, payload, onError) =>
  handleRequest("put", url, payload, redirectUrl, null, onError)

export const put = (url, payload, onSuccess, onError, onFinally) =>
  handleRequest("put", url, payload, null, onSuccess, onError, onFinally)
export const replaceId = (url, id) => url.replace(":id", id)

const errors = response => response?.data?.errors || response?.data?.error

const joinErrors = errs => {
  if (typeof errs === "string") return errs
  return Object.values(errs).join("; ")
}

const getErrorMessage = response => {
  if (!response) return "Network error"
  if (response.status !== HTTP_UNPROCESSABLE_ENTITY) return "Server error"
  const error = errors(response)
  return error ? `Can't submit: ${joinErrors(error)}` : "Can't submit"
}

export const showErrorMessage = error => {
  Alerter.error(getErrorMessage(error.response))
}
export const showAllErrorsInAlert = ({ response }) => {
  if (!response) {
    Alerter.error("Network error")
  } else if (response.status !== HTTP_UNPROCESSABLE_ENTITY) {
    Alerter.error("Server error")
  } else {
    Object.entries(errors(response) || {}).forEach(([key, value]) => {
      const message = key !== "base" ? `Can't submit: ${key} - ${joinErrors(value)}` : value
      Alerter.error(message)
    })
  }
}

export const showBaseErrorsInAlert = ({ response }) => {
  if (!response) {
    Alerter.error("Network error")
  } else if (response.status !== HTTP_UNPROCESSABLE_ENTITY) {
    Alerter.error("Server error")
  } else if (errors(response)?.base) {
    Object.values(errors(response).base).forEach(message => Alerter.error(message))
  } else {
    Alerter.error("Can't submit")
  }
}

export default apiClient
