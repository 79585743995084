import Alerter from "@/common/alerter"
import apiClient from "@/common/apiClient"
import { FILTER_DISPLAY_NAMES } from "@/constants"
import { FilterBox, FilterChip, TextField } from "@/library"
import FilterListIcon from "@mui/icons-material/FilterList"
import GradeIcon from "@mui/icons-material/Grade"
import GroupIcon from "@mui/icons-material/Group"
import SchoolIcon from "@mui/icons-material/School"
import SearchIcon from "@mui/icons-material/Search"
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { memo, useCallback, useEffect, useMemo, useState } from "react"

const INITIAL_FILTERS = {
  schools: [],
  groups: [],
  grades: [],
  statuses: [],
}

const FilterInputs = ({ filterModel, onFilterModelChange, onResetFilters }) => {
  const [filters, setFilters] = useState(null)

  const fetchFilters = useCallback(async (schoolIds = []) => {
    const params = schoolIds.length > 0 ? { schools: schoolIds } : {}
    try {
      const url = "/api/v2/admins/students/filters"
      const { data } = await apiClient(url, { params })
      setFilters(prevFilters => ({ ...prevFilters, ...data }))
    } catch (error) {
      Alerter.error("Error fetching filters:", error)
    }
  }, [])

  useEffect(() => {
    if (!filters) {
      fetchFilters()
    }
  }, [filters, fetchFilters])

  const handleFilterChange = useCallback(
    (field, value) => {
      const newFilterModel = {
        ...filterModel,
        items: filterModel.items.filter(item => item.field !== field),
      }

      if (value !== null && value !== undefined && (typeof value !== "object" || Object.keys(value).length > 0)) {
        newFilterModel.items.push({
          field,
          operator: Array.isArray(value) ? "in" : "equals",
          value: value,
        })
      }

      if (field === "schools") {
        newFilterModel.items = newFilterModel.items.filter(item => item.field !== "groups")
        if (value && value.length > 0) fetchFilters(value)
      }

      onFilterModelChange(newFilterModel)
    },
    [filterModel, onFilterModelChange, fetchFilters]
  )

  const handleRemoveFilter = useCallback(
    field => {
      const newFilterModel = {
        ...filterModel,
        items: filterModel.items.filter(item => item.field !== field),
      }
      onFilterModelChange(newFilterModel)
    },
    [filterModel, onFilterModelChange]
  )

  const handleResetFilters = useCallback(() => {
    fetchFilters()
    onResetFilters()
    setFilters(INITIAL_FILTERS)
  }, [fetchFilters, onResetFilters])

  const renderTextField = useCallback(
    (label, field, icon) => {
      const filterItem = filterModel.items.find(item => item.field === field)
      return (
        <TextField
          fullWidth
          label={label}
          name={field}
          type="search"
          value={filterItem ? filterItem.value : ""}
          onChange={e => handleFilterChange(field, e.target.value)}
          InputProps={{
            startAdornment: icon,
          }}
          placeholder={label}
          sx={{
            "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
              color: theme => (filterItem ? theme.palette.primary.main : theme.palette.text.disabled),
            },
          }}
        />
      )
    },
    [filterModel, handleFilterChange]
  )

  const renderAutocomplete = useCallback(
    (label, field, options, icon) => {
      const filterItem = filterModel.items.find(item => item.field === field)
      let value = null

      const isMultipleSelect = ["grades", "groups", "schools"].includes(field)

      if (isMultipleSelect) {
        value = filterItem ? options.filter(option => filterItem.value.includes(option.id)) : []
      } else {
        value = filterItem ? options.find(option => option.id === filterItem.value) : null
      }

      return (
        <Autocomplete
          multiple={isMultipleSelect}
          limitTags={2}
          options={options}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    {icon}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
              placeholder={`Select ${label.toLowerCase()}`}
            />
          )}
          onChange={(event, newValue) => {
            if (isMultipleSelect) {
              handleFilterChange(
                field,
                newValue.map(v => v.id)
              )
            } else {
              handleFilterChange(field, newValue ? newValue.id : null)
            }
          }}
          value={value}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          sx={{
            "& .MuiSvgIcon-root.MuiSvgIcon-colorAction": {
              color: theme =>
                (isMultipleSelect && value.length > 0) || (!isMultipleSelect && value)
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
            },
          }}
        />
      )
    },
    [filterModel, handleFilterChange]
  )

  const renderStatusSwitch = useCallback(() => {
    const filterItem = filterModel.items.find(item => item.field === "statuses")
    const showAllStudents = filterItem ? filterItem.value === "all" : false

    return (
      <FormControlLabel
        control={
          <Switch
            checked={showAllStudents}
            onChange={event => {
              if (event.target.checked) {
                handleFilterChange("statuses", "all")
              } else {
                const newFilterModel = {
                  ...filterModel,
                  items: filterModel.items.filter(item => item.field !== "statuses"),
                }
                onFilterModelChange(newFilterModel)
              }
            }}
            color="primary"
          />
        }
        label={showAllStudents ? "Showing all students" : "Showing only active students"}
      />
    )
  }, [filterModel, handleFilterChange, onFilterModelChange])

  return !filters ? (
    <div>Loading filters...</div>
  ) : (
    <FilterBox>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" component="h2">
          <FilterListIcon sx={{ mr: 1, verticalAlign: "middle" }} />
          Student Filters
        </Typography>
        <Tooltip title="Reset all filters">
          <Button
            component="label"
            variant="outlined"
            color="primary"
            onClick={handleResetFilters}
            size="small"
            startIcon={<FilterListIcon />}
          >
            Reset
          </Button>
        </Tooltip>
      </Box>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={12} sm={6} md={4}>
          {renderTextField("Filter by Name", "name", <SearchIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderAutocomplete("Filter by School", "schools", filters.schools, <SchoolIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderAutocomplete("Filter by Group", "groups", filters.groups, <GroupIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderAutocomplete("Filter by Grade", "grades", filters.grades, <GradeIcon color="action" />)}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {renderStatusSwitch()}
        </Grid>
      </Grid>
      <FilterChips filterModel={filterModel} filters={filters} handleRemoveFilter={handleRemoveFilter} />
    </FilterBox>
  )
}

export default memo(FilterInputs)

const FilterChips = ({ filterModel, filters, handleRemoveFilter }) => {
  const renderFilterValue = useMemo(
    () => (field, value) => {
      switch (field) {
        case "groups":
        case "schools":
        case "grades":
          return Array.isArray(value)
            ? value.map(id => filters[field]?.find(option => option.id === id)?.name || "Unknown").join(", ")
            : "Unknown"
        case "statuses":
          return value === "active" ? "Only Active" : "All Students"
        default:
          return filters[field] ? filters[field].find(option => option.id === value)?.name || value : value
      }
    },
    [filters]
  )

  const activeFilters = filterModel.items.filter(item => {
    if (item.field === "statuses" && item.value === "active") return false
    if (typeof item.value === "string" && item.value.trim() === "") return false
    return item.value !== null && item.value !== undefined
  })

  if (activeFilters.length === 0) return null
  return (
    <Box mt={2}>
      <Typography variant="subtitle2" gutterBottom>
        Active Filters:
      </Typography>
      {activeFilters.map(({ field, value }) => (
        <FilterChip
          key={field}
          label={`${FILTER_DISPLAY_NAMES[field]}: ${renderFilterValue(field, value)}`}
          onDelete={() => handleRemoveFilter(field)}
          color="primary"
          variant="outlined"
        />
      ))}
    </Box>
  )
}
